var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c("a", _vm._g(_vm._b({}, "a", attrs, false), on), [
                _vm._v(
                  " " + _vm._s(_vm.$t("connector.website.btn.linkMethod")) + " "
                )
              ])
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { tile: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("connector.website.dialog.linkMethodTitle")
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-text", [
            _vm._v(
              " 1. " + _vm._s(_vm.$t("connector.website.dialog.linkMethodNo1"))
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " 2. " + _vm._s(_vm.$t("connector.website.dialog.linkMethodNo2"))
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " 3. " +
                _vm._s(
                  _vm.$t("connector.website.dialog.linkMethodNo3", [_vm.url])
                )
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " 4. " + _vm._s(_vm.$t("connector.website.dialog.linkMethodNo4"))
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " 5. " +
                _vm._s(_vm.$t("connector.website.dialog.linkMethodNo5")) +
                " "
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "connector_dialog--action" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", depressed: "", rounded: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.acknowledged")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
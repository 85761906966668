var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "450px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        color: "red",
                        dark: "",
                        depressed: "",
                        blocked: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("connector.shopee.btn.logout")) + " "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dense: "" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "text-center" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-center text-center" },
              [
                _c(
                  "v-icon",
                  { attrs: { color: "yellow darken-2", size: "56" } },
                  [_vm._v(" $logoutyellow ")]
                ),
                _c("title-label", {
                  staticClass: "my-3",
                  attrs: {
                    label: _vm.$t("connector.shopee.field.logout.dialog.title")
                  }
                })
              ],
              1
            ),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("connector.shopee.field.logout.dialog.description")
                )
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "grey lighten-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("connector.shopee.btn.close")) + " "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "red", dark: "", depressed: "", rounded: "" },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("connector.shopee.btn.logout")) + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-dialog
    v-model="dialog"
    max-width="450px"
    scrollable
    @input="cancel($event)">
    <template v-slot:activator="{}">
      <toggle-form
        v-model="form.syncShopeeStock"
        :state="state['syncShopeeStock']"
        :text="$t('connector.shopee.field.stock.label')"
        @input="confirm('syncShopeeStock')" />
    </template>
    <v-card
      class="app-card"
      tile>
      <v-card-title>
        <v-spacer />
        <v-btn
          icon
          small
          @click="cancel()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <div class="d-flex flex-column align-center text-center">
          <v-icon
            color="red lighten-3"
            size="100">
            $emptystock
          </v-icon>
          <div class="d-flex mb-3">
            <title-label
              :label="$t('connector.shopee.field.stock.dialog.title')"
              class="mr-2" />
          </div>
          <div class="mb-3 caption">
            {{ $t('connector.shopee.field.stock.dialog.description') }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="connector_dialog--action">
        <v-spacer />
        <v-btn
          color="primary"
          rounded
          text
          @click="submit('syncShopeeStock')">
          {{ $t('connector.shopee.btn.updateStock') }}
        </v-btn>
        <v-btn
          :href="`${shipnityUrl}/products`"
          target="_blank"
          color="primary"
          depressed
          rounded
          @click="addStock()">
          <v-icon
            left
            small>
            mdi-plus-circle
          </v-icon>
          {{ $t('connector.shopee.btn.addStock') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { GET_SETTING_SHOPEE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHOPEE,
      result ({ data: { setting } }) {
        this.shopId = setting.id
        this.form.syncShopeeStock = setting.syncShopeeStock
      }
    })
  },
  data () {
    return {
      shipnityUrl: process.env.VUE_APP_SHIPNITY,
      dialog: false,
      shopId: null,
      state: {
        syncShopeeStock: 'ready'
      },
      form: {
        syncShopeeStock: false
      }
    }
  },
  methods: {
    confirm (field) {
      this.dialog = this.form[field]
      this.cancel(this.dialog)
      if (!this.dialog) {
        this.submit(field)
      }
    },
    cancel (dialog = false) {
      if (!dialog) {
        this.form.syncShopeeStock = false
        this.dialog = false
      } else {
        this.state.syncShopeeStock = 'ready'
      }
    },
    addStock () {
      this.cancel()
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
      this.dialog = false
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

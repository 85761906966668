<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="cancel('shopeeIncludeShippingFee')">
    <template v-slot:activator="{}">
      <toggle-form
        v-model="form.shopeeIncludeShippingFee"
        :state="state['shopeeIncludeShippingFee']"
        :text="$t('connector.shopee.field.shippingFee.label')"
        @change="confirm('shopeeIncludeShippingFee')" />
    </template>
    <v-card
      class="app-card"
      tile>
      <v-card-title>
        <div class="d-flex text-subtitle-1">
          <v-icon
            color="yellow darken-2"
            class="mr-4 icon-circle-yellow">
            mdi-exclamation
          </v-icon>
          <span class="yellow--text text--darken-2">
            {{ $t('connector.shopee.field.shippingFee.dialog.title') }}
          </span>
        </div>
        <v-spacer />
        <v-btn
          icon
          small
          @click="cancel('shopeeIncludeShippingFee')">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="mx-12">
          <div class="secondary--text subtitle-1 dialog-text">
            {{ $t('connector.shopee.field.shippingFee.dialog.description') }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="connector_dialog--action">
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="cancel('shopeeIncludeShippingFee')">
          {{ $t('connector.shopee.btn.close') }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          rounded
          @click="submit('shopeeIncludeShippingFee')">
          {{ $t('connector.shopee.btn.connectShippingFee') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { GET_SETTING_SHOPEE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHOPEE,
      result ({ data: { setting } }) {
        this.form.shopeeIncludeShippingFee = setting.shopeeIncludeShippingFee
      }
    })
  },
  data () {
    return {
      shipnityUrl: process.env.VUE_APP_SHIPNITY,
      dialog: false,
      readMore: false,
      state: {
        shopeeIncludeShippingFee: 'ready'
      },
      form: {
        shopeeIncludeShippingFee: false
      }
    }
  },
  methods: {
    expandReadMore (value) {
      this.readMore = value
    },
    confirm (field) {
      this.dialog = this.form[field]
      if (!this.dialog) {
        this.submit(field)
      }
    },
    cancel (field) {
      this.form[field] = false
      this.dialog = false
    },
    async submit (field) {
      this.dialog = false
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>
  .icon-circle-yellow {
    border: 3px solid #fbc02d;
    border-radius: 50px
  }
  .dialog-text {
    line-height: 1.5rem !important;
    letter-spacing: .0178571429em !important;
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "450px", scrollable: "" },
      on: {
        input: function($event) {
          return _vm.cancel($event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            return [
              _c("toggle-form", {
                attrs: {
                  state: _vm.state["syncLazadaStock"],
                  text: _vm.$t("connector.lazada.field.stock.label")
                },
                on: {
                  input: function($event) {
                    return _vm.confirm("syncLazadaStock")
                  }
                },
                model: {
                  value: _vm.form.syncLazadaStock,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "syncLazadaStock", $$v)
                  },
                  expression: "form.syncLazadaStock"
                }
              })
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { tile: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "text-center" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-center text-center" },
              [
                _c(
                  "v-icon",
                  { attrs: { color: "red lighten-3", size: "100" } },
                  [_vm._v(" $emptystock ")]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex mb-3" },
                  [
                    _c("title-label", {
                      staticClass: "mr-2",
                      attrs: {
                        label: _vm.$t(
                          "connector.lazada.field.stock.dialog.title"
                        )
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "mb-3 caption" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "connector.lazada.field.stock.dialog.description"
                        )
                      ) +
                      " "
                  )
                ])
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "connector_dialog--action" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", rounded: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.submit("syncLazadaStock")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("connector.lazada.btn.updateStock")) +
                      " "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    href: _vm.shipnityUrl + "/products",
                    target: "_blank",
                    color: "primary",
                    depressed: "",
                    rounded: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.addStock()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v(" mdi-plus-circle ")
                  ]),
                  _vm._v(
                    " " + _vm._s(_vm.$t("connector.lazada.btn.addStock")) + " "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card
    class="app-card"
    flat>
    <v-card-title class="d-flex align-center py-0">
      <text-field-label
        :label="$t('connector.analyze.updateGoogleTitle')" />
      <v-spacer />
      <title-label
        v-if="!dialog"
        class="mr-3"
        :label="statusText" />
      <v-btn
        v-if="dialog"
        class="text-subtitle-1"
        color="blue darken-1"
        text
        @click="closeDialog()">
        {{ $t('app.btn.cancel') }}
      </v-btn>
      <v-btn
        v-else
        class="text-subtitle-1"
        color="primary"
        text
        @click="openDialog()">
        {{ btnTextEdit }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-expand-transition>
        <div v-if="dialog">
          <v-form
            ref="form"
            v-model="valid"
            :readonly="isReadonly"
            lazy-validation
            @submit.prevent="submit()">
            <v-row>
              <v-col
                cols="5"
                md="3"
                offset-md="1">
                <text-field-label :label="$t('connector.analyze.field.googleAnalysisCode.label')" />
              </v-col>
              <v-col
                cols="7"
                md="5">
                <v-text-field
                  v-model="form.googleAnalyticsTag"
                  :placeholder="$t('connector.analyze.field.googleAnalysisCode.placeholder')"
                  :error-messages="error.googleAnalyticsTag"
                  dense
                  outlined
                  @input="markAsDirty($event)"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                offset="5"
                offset-md="4"
                cols="4">
                <save-change-button
                  class="mr-1"
                  :state="state"
                  :disabled="!isDirty || !valid"
                  @click="submit()" />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { GET_SETTING_GOOGLEANALYSIS, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      query: GET_SETTING_GOOGLEANALYSIS,
      watchLoading (isLoading) {
        this.setLoading(isLoading)
      },
      result ({ data: { setting } }) {
        this.form.googleAnalyticsTag = setting.googleAnalyticsTag
      }
    })
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      form: {
        googleAnalyticsTag: null
      },
      error: {
        googleAnalyticsTag: []
      }
    }
  },
  computed: {
    statusText () {
      return this.form.googleAnalyticsTag
      ? this.$t('connector.analyze.btn.available')
      : this.$t('connector.analyze.btn.unavailable')
    },
    btnTextEdit () {
      return this.form.googleAnalyticsTag
      ? this.$t('app.btn.edit')
      : this.$t('connector.analyze.btn.available')
    },
    isReadonly () {
      return this.state === 'loading'
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
      this.error = {
        googleAnalyticsTag: []
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_SETTING,
          variables: {
            input: {
              googleAnalyticsTag: this.form.googleAnalyticsTag
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          const { data: { updateSetting } } = res
          if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "900px", scrollable: "" },
      on: {
        input: function($event) {
          return _vm.changeDialog($event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-2",
                      attrs: { color: "indigo darken-4", outlined: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("connector.lazada.btn.import")) + " "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dense: "" } },
            [
              _c("title-label", {
                attrs: { label: _vm.$t("connector.lazada.importTitle") }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                staticClass: "app-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.lazadaProducts,
                  "items-per-page": _vm.pageSize,
                  loading: _vm.isFetching,
                  "item-key": "code",
                  height: "500",
                  "fixed-header": "",
                  "hide-default-footer": ""
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              }),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _vm.hasMore && _vm.lazadaProducts.length >= _vm.pageSize
                    ? _c(
                        "v-btn",
                        {
                          attrs: { small: "", disabled: _vm.isFetching },
                          on: {
                            click: function($event) {
                              return _vm.fetchMore()
                            }
                          }
                        },
                        [_vm._v(" โหลดข้อมูลเพิ่มเติม ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "grey lighten-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("connector.lazada.btn.close")) + " "
                  )
                ]
              ),
              _c("save-change-button", {
                attrs: {
                  state: _vm.state,
                  label: _vm.$t("connector.lazada.btn.importSelected"),
                  disabled: !_vm.hasSelected
                },
                on: {
                  click: function($event) {
                    return _vm.importData($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-simple-table", {
        staticClass: "app-table",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c(
                    "tr",
                    _vm._l(_vm.headers, function(header, i) {
                      return _c("th", { key: "th_lazada-" + i }, [
                        _vm._v(" " + _vm._s(header.text) + " ")
                      ])
                    }),
                    0
                  ),
                  _vm.stocks.nodes.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: _vm.headers.length } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column justify-center py-16 text-center"
                            },
                            [
                              _c("text-field-label", {
                                staticClass: "mb-3 justify-center",
                                attrs: {
                                  label: _vm.$t("app.table.emptyMessage", [
                                    _vm.$t("connector.lazada.title")
                                  ])
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.stocks.nodes, function(item) {
                    return _c("tr", { key: "th_lazada-" + item.id }, [
                      _c("td", [_vm._v(_vm._s(item.name))]),
                      _c("td", [
                        item.lazadaAccessToken
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      color: "green accent-4",
                                      "offset-x": "-12",
                                      "offset-y": "11",
                                      dot: "",
                                      left: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "green--text text--accent-4"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "connector.lazada.table.body.status.available.label"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "red--text caption" },
                                  [
                                    item.lazadaLoginDayLeft > 0
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "connector.lazada.table.body.expire.label"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(item.lazadaLoginDayLeft) +
                                              " วัน "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "connector.lazada.table.body.expired.label"
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      color: "grey",
                                      "offset-x": "-12",
                                      "offset-y": "11",
                                      dot: "",
                                      left: ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "connector.lazada.table.body.status.unavailable.label"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                      ]),
                      _c(
                        "td",
                        [
                          item.lazadaAccessToken
                            ? [
                                _c("lazada-import", {
                                  staticClass: "mr-2",
                                  attrs: { "item-stock-id": item.id }
                                }),
                                _c("lazada-history", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    "item-stock-id": item.id,
                                    "item-stock-name": item.name
                                  }
                                }),
                                _c("lazada-logout", {
                                  attrs: { "item-stock-id": item.id },
                                  on: {
                                    submit: function($event) {
                                      return _vm.refetch()
                                    }
                                  }
                                })
                              ]
                            : [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      href: item.lazadaAuth,
                                      color: "indigo darken-4",
                                      outlined: ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "connector.lazada.btn.connectLazada"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("v-pagination", {
        attrs: { length: _vm.stocks.pagesCount },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./LazadaDataTable.vue?vue&type=template&id=f341687c&scoped=true&"
import script from "./LazadaDataTable.vue?vue&type=script&lang=js&"
export * from "./LazadaDataTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f341687c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBadge,VBtn,VPagination,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f341687c')) {
      api.createRecord('f341687c', component.options)
    } else {
      api.reload('f341687c', component.options)
    }
    module.hot.accept("./LazadaDataTable.vue?vue&type=template&id=f341687c&scoped=true&", function () {
      api.rerender('f341687c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Connector/components/Lazada/LazadaDataTable.vue"
export default component.exports
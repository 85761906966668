<template>
  <v-card
    class="app-card"
    flat>
    <v-card-title class="d-flex align-center pb-0">
      <text-field-label
        :label="$t('connector.website.cloudflare')" />
      <v-spacer />
      <title-label
        v-if="!dialog"
        class="mr-3"
        :label="statusText" />
      <v-btn
        v-if="dialog"
        class="text-subtitle-1"
        color="blue darken-1"
        text
        @click="setDialog(false)">
        {{ $t('app.btn.cancel') }}
      </v-btn>
      <v-btn
        v-else
        class="text-subtitle-1"
        color="primary"
        text
        @click="setDialog(true)">
        {{ btnTextEdit }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-expand-transition>
        <div v-if="dialog">
          <v-form
            ref="form"
            v-model="valid"
            :readonly="isReadonly"
            lazy-validation
            @submit.prevent="submit()">
            <v-row dense>
              <v-col
                cols="5"
                md="3"
                offset-md="1">
                <text-field-label :label="$t('connector.website.field.cloudflareAuthEmail.label')" />
              </v-col>
              <v-col
                cols="7"
                md="6">
                <v-text-field
                  v-model="form.cloudflareAuthEmail"
                  :rules="rule.cloudflareAuthEmail"
                  :error-messages="error.cloudflareAuthEmail"
                  dense
                  outlined
                  @input="markAsDirty($event)"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                class="pb-0"
                cols="5"
                md="3"
                offset-md="1">
                <text-field-label :label="$t('connector.website.field.cloudflareAuthKey.label')" />
              </v-col>
              <v-col
                class="pb-0"
                cols="7"
                md="6">
                <v-text-field
                  v-model="form.cloudflareAuthKey"
                  :rules="rule.cloudflareAuthKey"
                  :error-messages="error.cloudflareAuthKey"
                  dense
                  outlined
                  @input="markAsDirty($event)"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                class="py-3"
                offset="5"
                offset-md="4"
                cols="4">
                <save-change-button
                  class="mr-1"
                  :state="state"
                  :disabled="!isDirty || !valid"
                  @click="submit()" />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { GET_SETTING_CLOUNDFLARE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      query: GET_SETTING_CLOUNDFLARE,
      watchLoading (isLoading) {
        this.setLoading(isLoading)
      },
      result ({ data: { setting } }) {
        this.form.cloudflareAuthEmail = setting.cloudflareAuthEmail
        this.form.cloudflareAuthKey = setting.cloudflareAuthKey
      }
    })
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      form: {
        cloudflareAuthEmail: null,
        cloudflareAuthKey: null
      },
      rule: {
        cloudflareAuthEmail: [
          (v) => !v || /.+@.+\..+/.test(v) || this.$t('app.validate.email')
        ],
        cloudflareAuthKey: []
      },
      error: {
        cloudflareAuthEmail: [],
        cloudflareAuthKey: []
      }
    }
  },
  computed: {
    statusText () {
      return (this.form.cloudflareAuthEmail && this.form.cloudflareAuthKey)
      ? this.$t('connector.website.btn.available')
      : this.$t('connector.website.btn.unavailable')
    },
    btnTextEdit () {
      return (this.form.cloudflareAuthEmail && this.form.cloudflareAuthKey)
      ? this.$t('app.btn.edit')
      : this.$t('connector.website.btn.available')
    },
    isReadonly () {
      return this.state === 'loading'
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
      this.error = {
        cloudflareAuthEmail: [],
        cloudflareAuthKey: []
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_SETTING,
          variables: {
            input: {
              cloudflareAuthEmail: this.form.cloudflareAuthEmail,
              cloudflareAuthKey: this.form.cloudflareAuthKey
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          const { data: { updateSetting } } = res
          if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

<template>
  <select-form
    v-model="form.shopeeSellerOwnFleetChangeTo"
    :text="$t('connector.shopee.field.sellerOwnFleetChangeTo.label')"
    :placeholder="$t('connector.shopee.field.sellerOwnFleetChangeTo.placeholder')"
    :items="shippingTypes"
    item-value="name"
    item-text="description"
    :state="state['shopeeSellerOwnFleetChangeTo']"
    class="shippingTypes-input-style mr-3"
    @input="submit('shopeeSellerOwnFleetChangeTo')" />
</template>

<script>
import _ from 'lodash'
import { GET_SETTING_SHOPEE, GET_SETTING_SHIPPING, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    shippingTypes: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHIPPING,
      update ({ setting }) {
        const optionExts = [{ name: '', description: this.$t('connector.shopee.field.sellerOwnFleetChangeTo.disabled') }]
        return _.concat(optionExts, setting.shippingTypes.map((value) => ({
          name: value,
          description: value
        })))
      }
    }),
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHOPEE,
      result ({ data: { setting } }) {
        this.form.shopeeSellerOwnFleetChangeTo = setting.shopeeSellerOwnFleetChangeTo
      }
    })
  },
  data () {
    return {
      shippingTypes: [],
      state: {
        shopeeSellerOwnFleetChangeTo: 'ready'
      },
      form: {
        shopeeSellerOwnFleetChangeTo: null
      }
    }
  },
  methods: {
    async submit (field) {
      this.dialog = false
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style>
  .shippingTypes-input-style .v-select {
    max-width: 200px;
  }
</style>

<style scoped>

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", scrollable: "" },
      on: {
        input: function($event) {
          return _vm.cancel("shopeeSyncOrder")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            return [
              _c("toggle-form", {
                attrs: {
                  state: _vm.state["shopeeSyncOrder"],
                  text: _vm.$t("connector.shopee.field.order.label")
                },
                on: {
                  change: function($event) {
                    return _vm.confirm("shopeeSyncOrder")
                  }
                },
                model: {
                  value: _vm.form.shopeeSyncOrder,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "shopeeSyncOrder", $$v)
                  },
                  expression: "form.shopeeSyncOrder"
                }
              })
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { tile: "" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "div",
                { staticClass: "d-flex text-subtitle-1" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-4 icon-circle-yellow",
                      attrs: { color: "yellow darken-2" }
                    },
                    [_vm._v(" mdi-exclamation ")]
                  ),
                  _c("span", { staticClass: "yellow--text text--darken-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("connector.shopee.field.order.dialog.title")
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancel("shopeeSyncOrder")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "mx-12" },
              [
                _c(
                  "div",
                  { staticClass: "secondary--text subtitle-1 dialog-text" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "connector.shopee.field.order.dialog.description"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _vm.readMore
                  ? _c("v-divider", { staticClass: "my-3" })
                  : _vm._e(),
                _vm.readMore
                  ? _c(
                      "div",
                      [
                        _c("title-label", {
                          staticClass: "mb-3",
                          attrs: { label: _vm.$t("connector.shopee.btn.learn") }
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-3 grey--text text-subtitle-1 dialog-text"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "connector.shopee.field.order.dialog.note"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              href: _vm.shipnityUrl + "/products",
                              target: "_blank",
                              color: "primary",
                              dense: "",
                              outlined: ""
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("connector.shopee.btn.product")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "primary--text mt-4 d-block subtitle-1",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.expandReadMore(true)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("connector.shopee.btn.learn")) +
                            "... "
                        )
                      ]
                    )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "connector_dialog--action" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancel("shopeeSyncOrder")
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("connector.shopee.btn.close")) + " "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", depressed: "", rounded: "" },
                  on: {
                    click: function($event) {
                      return _vm.submit("shopeeSyncOrder")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("connector.shopee.btn.connectOrder")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on">
        {{ $t('connector.website.btn.linkMethod') }}
      </a>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-card-title>
        <title-label :label="$t('connector.website.dialog.linkMethodTitle')" />
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        1. {{ $t('connector.website.dialog.linkMethodNo1') }}<br /><br />
        2. {{ $t('connector.website.dialog.linkMethodNo2') }}<br /><br />
        3. {{ $t('connector.website.dialog.linkMethodNo3', [url]) }}<br /><br />
        4. {{ $t('connector.website.dialog.linkMethodNo4') }}<br /><br />
        5. {{ $t('connector.website.dialog.linkMethodNo5') }}
      </v-card-text>
      <v-card-actions class="connector_dialog--action">
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          rounded
          @click="closeDialog()">
          {{ $t('app.btn.acknowledged') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

import { render, staticRenderFns } from "./Connector.vue?vue&type=template&id=2ac385be&scoped=true&"
import script from "./Connector.vue?vue&type=script&lang=js&"
export * from "./Connector.vue?vue&type=script&lang=js&"
import style0 from "./Connector.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac385be",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardText,VCol,VRow,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ac385be')) {
      api.createRecord('2ac385be', component.options)
    } else {
      api.reload('2ac385be', component.options)
    }
    module.hot.accept("./Connector.vue?vue&type=template&id=2ac385be&scoped=true&", function () {
      api.rerender('2ac385be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Connector/Connector.vue"
export default component.exports
<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-2"
        color="indigo darken-4"
        outlined
        v-bind="attrs"
        v-on="on">
        {{ $t('connector.lazada.btn.import') }}
      </v-btn>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-toolbar
        flat
        dense>
        <title-label :label="$t('connector.lazada.importTitle')" />
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="lazadaProducts"
          :items-per-page="pageSize"
          :loading="isFetching"
          item-key="code"
          class="app-table"
          height="500"
          fixed-header
          hide-default-footer />
        <div class="text-center">
          <v-btn
            v-if="hasMore && lazadaProducts.length >= pageSize"
            small
            :disabled="isFetching"
            @click="fetchMore()">
            โหลดข้อมูลเพิ่มเติม
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions class="grey lighten-5">
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog()">
          {{ $t('connector.lazada.btn.close') }}
        </v-btn>
        <save-change-button
          :state="state"
          :label="$t('connector.lazada.btn.importSelected')"
          :disabled="!hasSelected"
          @click="importData($event)" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { GET_LAZADAPRODUCTS, CREATE_LAZADAPRODUCT } from '@/resources/graphql'

export default {
  apollo: {
    lazadaProducts: () => ({
      skip: true,
      fetchPolicy: 'no-cache',
      query: GET_LAZADAPRODUCTS,
      variables () {
        return {
          page: 1,
          perPage: this.pageSize,
          stockId: this.itemStockId
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
      }
    })
  },
  props: {
    itemStockId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      selected: [],
      lazadaProducts: [],
      page: 1,
      pageSize: 50,
      isFetching: false,
      state: 'ready',
      headers: [
        { text: this.$t('connector.lazada.table.header.productCode'), value: 'code', sortable: false },
        { text: this.$t('connector.lazada.table.header.productName'), value: 'name', sortable: false },
        { text: this.$t('connector.lazada.table.header.productPrice'), value: 'price', sortable: false },
        { text: this.$t('connector.lazada.table.header.productStock'), value: 'total', sortable: false }
      ],
      hasMore: true
    }
  },
  computed: {
    hasSelected () {
      return this.lazadaProducts.length > 0
    }
  },
  methods: {
    changeDialog (value) {
      this.$apollo.queries.lazadaProducts.skip = !value
      if (value) {
        this.$apollo.queries.lazadaProducts.refetch()
      }
    },
    closeDialog () {
      this.dialog = false
    },
    fetchMore () {
      this.page++
      this.$apollo.queries.lazadaProducts.fetchMore({
        variables: {
          page: this.page
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.lazadaProducts.length === 0) {
            this.hasMore = false
            return prev
          }
          return {
            lazadaProducts: [...prev.lazadaProducts, ...fetchMoreResult.lazadaProducts]
          }
        }
      })
    },
    async importData () {
      this.state = 'loading'
      const res = await this.$apollo.mutate({
        mutation: CREATE_LAZADAPRODUCT,
        variables: {
          input: {
            id: Number(this.itemStockId)
          }
        }
      })

      if (res) {
        const { data: { lazadaImportProduct } } = res
        if (lazadaImportProduct.errors && Object.keys(lazadaImportProduct.errors).length) {
          this.state = 'error'
        } else {
          this.state = 'success'
          this.$apollo.queries.lazadaProducts.refetch()
        }
      }
    }
  }
}
</script>

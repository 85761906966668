<template>
  <v-dialog
    v-model="dialog"
    max-width="350px"
    scrollable
    @input="changeDialog()">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-2"
        color="red"
        outlined
        v-bind="attrs"
        v-on="on">
        {{ $t('connector.shopee.btn.pull') }}
      </v-btn>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-toolbar
        flat
        dense>
        <title-label :label="$t('connector.shopee.pullTitle', [itemStockName])" />
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          :readonly="isSubmitting"
          lazy-validation
          @submit.prevent="submit()">
          <text-field-label :label="$t('connector.shopee.field.pull.label')" />
          <v-text-field
            v-model="form.startDate"
            :rules="rule.startDate"
            :error-messages="error.startDate"
            type="date"
            dense
            outlined />
        </v-form>
      </v-card-text>
      <v-card-actions class="grey lighten-5">
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog()">
          {{ $t('connector.lazada.btn.close') }}
        </v-btn>
        <save-change-button
          :state="state"
          :label="$t('connector.shopee.btn.pull')"
          :disabled="!valid"
          prepend-icon-ready=""
          @click="submit()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { SHOPEE_FETCHORDER } from '@/resources/graphql'

export default {
  props: {
    itemStockName: {
      type: [String],
      default: ''
    },
    itemStockId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      form: {
        startDate: false
      },
      rule: {
        startDate: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        startDate: []
      }
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    changeDialog () {
      this.state = 'ready'
    },
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: SHOPEE_FETCHORDER,
          variables: {
            input: {
              id: Number(this.itemStockId),
              startDate: this.form.startDate
            }
          }
        })

        if (res) {
          const { data: { shopeeFetchOrder } } = res
          if (shopeeFetchOrder.errors && Object.keys(shopeeFetchOrder.errors).length) {
            this.state = 'error'
            this.error = shopeeFetchOrder.errors
          } else {
            this.state = 'success'
            this.$emit('submit')
            this.$refs.form.reset()
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>

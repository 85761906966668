<template>
  <v-dialog
    v-model="dialog"
    max-width="450px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red"
        dark
        depressed
        v-bind="attrs"
        v-on="on">
        {{ $t('connector.lazada.btn.logout') }}
      </v-btn>
    </template>
    <v-card
      class="app-card"
      tile>
      <v-toolbar
        flat
        dense>
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-center">
        <div class="d-flex flex-column align-center text-center">
          <v-icon
            color="yellow darken-2"
            size="56">
            $logoutyellow
          </v-icon>
          <title-label
            class="mb-3"
            :label="$t('connector.lazada.field.logout.dialog.title')" />
        </div>
        <div>{{ $t('connector.lazada.field.logout.dialog.description') }}</div>
      </v-card-text>
      <v-card-actions class="grey lighten-5">
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog()">
          {{ $t('connector.lazada.btn.close') }}
        </v-btn>
        <v-btn
          color="red"
          dark
          depressed
          rounded
          @click="submit()">
          {{ $t('connector.lazada.btn.logout') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { LAZADA_LOGOUT } from '@/resources/graphql'

export default {
  props: {
    itemStockId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      this.state = 'loading'
      const res = await this.$apollo.mutate({
        mutation: LAZADA_LOGOUT,
        variables: {
          input: {
            id: this.itemStockId
          }
        }
      }).catch(() => {
        this.state = 'error'
      })
      if (res) {
        const { data: { lazadaLogout } } = res
        if (lazadaLogout.errors && Object.keys(lazadaLogout.errors).length) {
          this.state = 'error'
        } else {
          this.state = 'success'
          this.$emit('submit')
          this.closeDialog()
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

<template>
  <div>
    <v-simple-table class="app-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="`th_shopee-${i}`">
              {{ header.text }}
            </th>
          </tr>
          <tr v-if="stocks.nodes.length === 0">
            <td :colspan="headers.length">
              <div class="d-flex flex-column justify-center py-16 text-center">
                <text-field-label
                  :label="$t('app.table.emptyMessage', [$t('connector.lazada.title')])"
                  class="mb-3 justify-center" />
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item) in stocks.nodes"
            :key="`th_shopee-${item.id}`">
            <td>{{ item.name }}</td>
            <td>
              <div v-if="item.shopeeShopId">
                <v-badge
                  color="green accent-4"
                  offset-x="-12"
                  offset-y="11"
                  dot
                  left>
                  <span class="green--text text--accent-4">
                    {{ $t('connector.lazada.table.body.status.available.label') }}
                  </span>
                  <br><i class="grey--text">{{ item.shopeeShopName }}</i>
                </v-badge>
              </div>
              <div v-else>
                <v-badge
                  color="grey"
                  offset-x="-12"
                  offset-y="11"
                  dot
                  left>
                  {{ $t('connector.lazada.table.body.status.unavailable.label') }}
                </v-badge>
              </div>
            </td>
            <td>
              <template v-if="item.shopeeShopId">
                <shopee-import
                  :item-stock-id="item.id"
                  class="mr-2" />
                <shopee-history
                  :item-stock-id="item.id"
                  :item-stock-name="item.name" />
                <shopee-logout
                  :item-stock-id="item.id"
                  @submit="refetch()" />
              </template>
              <template v-else>
                <v-btn
                  :href="item.shopeeAuth"
                  target="_blank"
                  color="red"
                  outlined>
                  {{ $t('connector.shopee.btn.connectShopee') }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      v-model="page"
      :length="stocks.pagesCount">
    </v-pagination>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ShopeeImport from '@/views/Connector/components/Shopee/ShopeeImport.vue'
import ShopeeHistory from '@/views/Connector/components/Shopee/ShopeeHistory.vue'
import ShopeeLogout from '@/views/Connector/components/Shopee/ShopeeLogout.vue'
import { GET_STOCKS } from '@/resources/graphql'

export default {
  apollo: {
    stocks: () => ({
      query: GET_STOCKS,
      variables () {
        return {
          page: this.page,
          perPage: this.pageSize
        }
      },
      watchLoading (isLoading) {
        this.setLoading(isLoading)
      }
    })
  },
  components: {
    ShopeeImport,
    ShopeeHistory,
    ShopeeLogout
  },
  data () {
    return {
      isFetching: false,
      page: 1,
      pageSize: 10,
      headers: [
        { text: this.$t('connector.shopee.table.header.store') },
        { text: this.$t('connector.shopee.table.header.status') },
        { text: '' }
      ],
      stocks: {
        nodes: [],
        pagesCount: 1
      }
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    refetch () {
      this.$apollo.queries.stocks.refetch()
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

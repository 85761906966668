var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-form", {
    staticClass: "shippingTypes-input-style mr-3",
    attrs: {
      text: _vm.$t("connector.shopee.field.sellerOwnFleetChangeTo.label"),
      placeholder: _vm.$t(
        "connector.shopee.field.sellerOwnFleetChangeTo.placeholder"
      ),
      items: _vm.shippingTypes,
      "item-value": "name",
      "item-text": "description",
      state: _vm.state["shopeeSellerOwnFleetChangeTo"]
    },
    on: {
      input: function($event) {
        return _vm.submit("shopeeSellerOwnFleetChangeTo")
      }
    },
    model: {
      value: _vm.form.shopeeSellerOwnFleetChangeTo,
      callback: function($$v) {
        _vm.$set(_vm.form, "shopeeSellerOwnFleetChangeTo", $$v)
      },
      expression: "form.shopeeSellerOwnFleetChangeTo"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card
    class="app-card"
    flat>
    <v-card-title class="d-flex align-center pb-0">
      <text-field-label
        :label="$t('connector.website.title')" />
      <v-spacer />
      <title-label
        v-if="!dialog"
        class="mr-3"
        :label="statusText" />
      <v-btn
        v-if="dialog"
        class="text-subtitle-1"
        color="blue darken-1"
        text
        @click="closeDialog()">
        {{ $t('app.btn.cancel') }}
      </v-btn>
      <v-btn
        v-else
        class="text-subtitle-1"
        color="primary"
        text
        @click="openDialog()">
        {{ btnTextEdit }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-expand-transition>
        <div v-if="dialog">
          <v-form
            ref="form"
            v-model="valid"
            :readonly="isReadonly"
            lazy-validation
            @submit.prevent="submit()">
            <v-row dense>
              <v-col
                cols="5"
                md="3"
                offset-md="1">
                <text-field-label
                  :label="$t('connector.website.field.url.label')"
                  required />
              </v-col>
              <v-col
                cols="7"
                md="6">
                <v-text-field
                  v-model="form.apiUrl"
                  :rules="rule.apiUrl"
                  :error-messages="error.apiUrl"
                  required
                  dense
                  outlined
                  @input="markAsDirty($event)"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="5"
                md="3"
                offset-md="1">
                <text-field-label
                  :label="$t('connector.website.field.websiteApiUser.label')"
                  required />
              </v-col>
              <v-col
                cols="7"
                md="6">
                <v-text-field
                  v-model="form.apiUser"
                  :rules="rule.apiUser"
                  :error-messages="error.apiUser"
                  required
                  dense
                  outlined
                  @input="markAsDirty($event)"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                class="pb-0"
                cols="5"
                md="3"
                offset-md="1">
                <text-field-label
                  :label="$t('connector.website.field.websiteApiKey.label')"
                  required />
              </v-col>
              <v-col
                class="pb-0"
                cols="7"
                md="6">
                <v-text-field
                  v-model="form.apiKey"
                  :rules="rule.apiKey"
                  :error-messages="error.apiKey"
                  required
                  dense
                  outlined
                  @input="markAsDirty($event)"
                />
              </v-col>
              <v-col
                class="pt-0"
                cols="7"
                md="6"
                offset="5"
                offset-md="4">
                <div class="d-flex">
                  <website-method-create-dialog />
                  <v-divider
                    class="ma-3"
                    vertical />
                  <website-link-method-dialog :url="woocommerceWebhookUrl" />
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                class="py-3"
                offset="5"
                offset-md="4"
                cols="4">
                <save-change-button
                  class="mr-1"
                  :state="state"
                  :disabled="!isDirty || !valid"
                  @click="submit()" />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { GET_SETTING_WEBSITE, UPDATE_SETTING } from '@/resources/graphql'
import WebsiteMethodCreateDialog from '@/views/Connector/components/Website/WebsiteMethodCreateDialog.vue'
import WebsiteLinkMethodDialog from '@/views/Connector/components/Website/WebsiteLinkMethodDialog.vue'

export default {
  apollo: {
    setting: () => ({
      query: GET_SETTING_WEBSITE,
      watchLoading (isLoading) {
        this.setLoading(isLoading)
      },
      result ({ data: { setting } }) {
        this.form.apiUrl = setting.apiUrl
        this.form.apiUser = setting.apiUser
        this.form.apiKey = setting.apiKey
        this.woocommerceWebhookUrl = setting.woocommerceWebhookUrl
      }
    })
  },
  components: {
    WebsiteMethodCreateDialog,
    WebsiteLinkMethodDialog
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      woocommerceWebhookUrl: '',
      form: {
        apiUrl: null,
        apiUser: null,
        apiKey: null
      },
      rule: {
        apiUrl: [
          (v) => !!v || this.$t('app.validate.required')
        ],
        apiUser: [
          (v) => !!v || this.$t('app.validate.required')
        ],
        apiKey: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        apiUrl: [],
        apiUser: [],
        apiKey: []
      }
    }
  },
  computed: {
    statusText () {
      return (this.form.apiUrl && this.form.apiUser && this.form.apiKey)
      ? this.$t('connector.website.btn.available')
      : this.$t('connector.website.btn.unavailable')
    },
    btnTextEdit () {
      return (this.form.apiUrl && this.form.apiUser && this.form.apiKey)
      ? this.$t('app.btn.edit')
      : this.$t('connector.website.btn.available')
    },
    isReadonly () {
      return this.state === 'loading'
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
      this.error = {
        apiUrl: [],
        apiUser: [],
        apiKey: []
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_SETTING,
          variables: {
            input: {
              apiUrl: this.form.apiUrl,
              apiUser: this.form.apiUser,
              apiKey: this.form.apiKey
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          const { data: { updateSetting } } = res
          if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
          }
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>

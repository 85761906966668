var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-label", {
        attrs: {
          label: _vm.$t("connector.header"),
          "prepend-icon": "$g_connector"
        }
      }),
      _c(
        "v-tabs",
        {
          staticClass: "app-tab",
          attrs: { "show-arrows": "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tabs-slider", { attrs: { color: "primary" } }),
          _vm._l(_vm.tabs, function(item, index) {
            return _c("v-tab", { key: index }, [
              _vm._v(" " + _vm._s(item) + " ")
            ])
          }),
          _c("v-spacer")
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "mb-5",
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { key: 0 },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("shopee-update-stock")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("shopee-connect-order")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("shopee-connect-shipping-fee")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("shopee-seller-own")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: 1 },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("lazada-update-stock")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("lazada-connect-order")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("lazada-connect-shipping-fee")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: 2 },
            [_c("website-connect-form"), _c("website-cloudflare-form")],
            1
          ),
          _c(
            "v-tab-item",
            { key: 3 },
            [
              _c("analyze-facebook-pixel"),
              _c("analyze-facebook-verify-domain"),
              _c("analyze-google-analysis")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { key: 0 },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center mb-3" },
                        [
                          _c("title-label", {
                            staticClass: "mr-3",
                            attrs: { label: _vm.$t("connector.shopee.title") }
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: "/shop-setting/warehouse",
                                color: "primary",
                                depressed: "",
                                rounded: ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("connector.shopee.btn.store")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("shopee-data-table")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: 1 },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center mb-3" },
                        [
                          _c("title-label", {
                            staticClass: "mr-3",
                            attrs: { label: _vm.$t("connector.lazada.title") }
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: "/shop-setting/warehouse",
                                color: "primary",
                                depressed: "",
                                rounded: ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("connector.lazada.btn.store")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("lazada-data-table")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-tab-item", { key: 2 }),
          _c("v-tab-item", { key: 3 })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }